import algoliasearch from "algoliasearch";
import { autocomplete, getAlgoliaFacets } from "@algolia/autocomplete-js";

let searchClient;
let index;

let query = '';
let hits = [];
let nbHits = 0;
const inputSearch = document.querySelector('input#searchTrips');
const searchButton = document.querySelector('button#searchBtn');
const openSearch = document.querySelector('.cta-container .search-btn');
const searchContainer = document.querySelector('.search-container');
const closeSearch = document.querySelector('.search-container .close-search');
const bodyOverlay = document.body.querySelector('.body-overlay');

searchClient = algoliasearch(
    import.meta.env.VITE_ALGOLIA_APPLICATION_ID,
    import.meta.env.VITE_ALGOLIA_SEARCH_API_KEY
);

index = searchClient.initIndex(import.meta.env.VITE_ALGOLIA_INDEX_ENVIRONMENT);

function limitCharacters(inputString, limit) {
  if (inputString.length <= limit) {
    return inputString;
  } else {
    return inputString.slice(0, limit) + '...';
  }
}

let searchResults = document.querySelector('div#searchResults');
async function search(query) {
  const results = await index.search(query, {
    hitsPerPage: 6,
  });
  hits = results.hits;
  nbHits = results.nbHits;
  searchResults.innerHTML = '';
  if(hits.length > 0) {
    hits.forEach((hit) => {
      const description = limitCharacters(hit.description, 125);
      searchResults.innerHTML += `
        <div class="search-results__item">
          <a href="${hit.url}">${hit.title}</a> <span class="description">${description}</span>
        </div>
      `;
    });
    searchResults.innerHTML += `<a class="view-all-search" href="/search-results/?s=${query}">View All Results (${nbHits})</a>`;

    if(searchResults.classList.contains('results-showed')) {
      searchResults.querySelectorAll('.search-results__item a').forEach((link) => {
        link.addEventListener('click', () => {
          closeSearchContainer();
        });
      });
    }

  } else {
    searchResults.innerHTML += `<div class="search-results__item">No results found for ${query}</div>`;
  }
}

inputSearch.addEventListener('input', async function (e) {
  if(e.target.value !== '') {
    search(e.target.value);
    searchResults.classList.add('results-showed');
  } else {
    searchResults.innerHTML = '';
    searchResults.classList.remove('results-showed');
  }
});

searchButton.addEventListener('click', function (e) {
    e.preventDefault();
    window.location.href = `/search-results/?s=${inputSearch.value}`;
});


if(openSearch) {
  openSearch.addEventListener('click', () => {
    searchContainer.classList.add('active');
    searchContainer.querySelector('input').focus();
    document.body.classList.add('overlay-active');
  });
}
if(closeSearch) {
  closeSearch.addEventListener('click', () => {
    closeSearchContainer();
  });
}
if(bodyOverlay) {
  bodyOverlay.addEventListener('click', () => {
    closeSearchContainer();
  });
}

function closeSearchContainer() {
  searchContainer.classList.remove('active');
  searchContainer.querySelector('input').value = '';
  searchResults.innerHTML = '';
  searchResults.classList.remove('results-showed');
  document.body.classList.remove('overlay-active');
}