import gsap from "gsap";

const textRows = document.querySelectorAll('section.text-accordion .text-row-item');
if(textRows.length > 0) {
    textRows.forEach((item) => {
        item.addEventListener('click', () => {
            setActive(item);
        });
    });
    const setActive = (item) => {
        if(item.classList.contains('active')) {
            gsap.to(item.children[1], {duration: 0.3, height: 0});
            item.classList.remove('active');
        }
        else {
            [...item.parentElement.children].forEach((sib)=> {
                gsap.to(sib.children[1], {duration: 0.3, height: 0});
                sib.classList.remove('active');
            });
            gsap.to(item.children[1], {duration: 0.3, height: 'auto'});
            item.classList.add('active');
        }
    }
}