import gsap from "gsap";

const mainNavItem = document.querySelectorAll('.main-nav-container .main-nav-menu__item.has-children');
if(mainNavItem) {
    mainNavItem.forEach((item) => {
        item.addEventListener('mouseover', () => {
            const megaMenuCta = item.querySelector('.mega-menu-container .sub-nav-container .mega-menu-cta').clientHeight;
            if(megaMenuCta) {
                item.querySelector('.sub-nav-container').style.minHeight = `${megaMenuCta}px`;
            }
        });
    });
}

const subNavItem = document.querySelectorAll('.main-nav-container .sub-nav-container__item.has-children');
if(subNavItem) {
    subNavItem.forEach((item) => {
        item.addEventListener('mouseover', () => {
            setActiveMenu(item, 'sub-nav-active');
        });
    });
}

const subNavGrandChild = document.querySelectorAll('.main-nav-container .sub-nav-child__item.has-children');
if(subNavGrandChild) {
    subNavGrandChild.forEach((item) => {
        item.addEventListener('mouseover', () => {
            setActiveMenu(item, 'sub-nav-grandchild-active');
        });
    });
}

const mobileNavItem = document.querySelectorAll('.mobile-menu-wrapper .mobile-nav-menu__item.has-children');
if(mobileNavItem) {
    mobileNavItem.forEach((item) => {
        item.addEventListener('click', (e) => {
            mobileSetActiveMenu(item, 'mobile-nav-active', '.mobile-submenu-container', e);
        });
    });
}

const mobileSubNavItem = document.querySelectorAll('.mobile-submenu-container .sub-nav-container .sub-nav-container__item.has-children');
if(mobileSubNavItem) {
    mobileSubNavItem.forEach((item) => {
        item.addEventListener('click', (e) => {
            e.stopPropagation();
            mobileSetActiveMenu(item, 'sub-nav-active', '.sub-nav-child');
        });
    });
}

const mobileSubNavGrandChild = document.querySelectorAll('.mobile-submenu-container .sub-nav-child .sub-nav-child__item.has-children');
if(mobileSubNavGrandChild) {
    mobileSubNavGrandChild.forEach((item) => {
        item.addEventListener('click', (e) => {
            e.stopPropagation();
            mobileSetActiveMenu(item, 'sub-nav-grandchild-active', '.sub-nav-grandchild');
        });
    });
}

const setActiveMenu = (item,  activeClass) => {
    if(!item.classList.contains(activeClass)) {
        [...item.parentElement.children].forEach((sib)=> {
            if(sib.children[1]) {
                gsap.to(sib.children[1], {duration: 0.3, autoAlpha: 0});
            }
            sib.classList.remove(activeClass);
        });
        gsap.to(item.children[1], {duration: 0.3, autoAlpha: 1});
        item.classList.add(activeClass);
    }
}

const mobileSetActiveMenu = (item, activeClass, subMenuClass, event) => {

    if(item.classList.contains(activeClass)) {
        item.classList.remove(activeClass);
        gsap.to(item.querySelector(subMenuClass), {duration: 0.3, height: 0});
    } else {
        [...item.parentElement.children].forEach((sib)=> {
            sib.classList.remove(activeClass);
            gsap.to(sib.querySelector(subMenuClass), {duration: 0.3, height: 0});
        });
        item.classList.add(activeClass);
        gsap.to(item.querySelector(subMenuClass), {duration: 0.3, height: 'auto'});
    }
}

const burgerIcon = document.querySelector('.mobile-menu-btn');
if(burgerIcon) {
    burgerIcon.addEventListener('click', () => {
        if(burgerIcon.classList.contains('active')) {
            burgerIcon.classList.remove('active');
            gsap.to('.mobile-menu-wrapper', {duration: 0.3, right: '-100%'});
        } else {
            burgerIcon.classList.add('active');
            gsap.to('.mobile-menu-wrapper', {duration: 0.3, right: 0});
        }
    });
}