import gsap from "gsap";

const navTabs = document.querySelectorAll('section.nav-tabs');
if (navTabs) {
  navTabs.forEach((nav) => {
    if(nav.classList.contains('link-scroll')) {
      nav.querySelectorAll('.nav-tabs-wrapper__item').forEach((item) => {
        item.addEventListener('click', (e) => {
          e.preventDefault();
          setActive(item);
        });
      });
      const setActive = (item) => {
        [...item.parentElement.children].forEach((sib)=> {
          sib.classList.remove('active');
        });
        item.classList.add('active');
        document.querySelector(item.children[0].getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
        });
      }
    }

    window.onscroll = function() {stickyNavbar()};
    var sticky = nav.offsetTop;
    let addSpace = 113;
    if(document.body.classList.contains('admin-bar-active')) {
      addSpace = 150
    }
    function stickyNavbar() {
      if (window.pageYOffset + addSpace >= sticky) {
        nav.classList.add("sticky");
      } else {
        nav.classList.remove("sticky");
      }
    }

  });
}