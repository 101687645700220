import flatpickr from "flatpickr";
import 'vanillajs-datepicker/css/datepicker-bulma.css';
import { Datepicker } from 'vanillajs-datepicker';


/*
===========================================
Formie add datepicker to date fields
===========================================
*/
const elements = document.querySelectorAll('input.datepicker1');
elements.forEach(elem => {
    const datepicker = new Datepicker(elem, {
        format: 'dd/mm/yyyy',
        autohide: true,
        prevArrow: '←',
        nextArrow: '→',
        startView: 2
    });
});



/*
===========================================
Sync Addons fields with actual formie fields
===========================================
*/

// populate front by default when you load the page if checkbox is selected
document.querySelectorAll('.addon-checkbox').forEach(checkbox => {
    if (checkbox.checked) {
        const checkbox_id = checkbox.getAttribute('id')
        const button = document.querySelector(`[data-id="${checkbox_id}"]`)
        const removeButton = document.querySelector(`#${checkbox_id}-remove`);
        button.innerHTML = 'Added';
        button.classList.remove('btn__primary');
        button.classList.add('btn__dark');
        removeButton.classList.remove('!hidden');
    }
});


// I need to catch the click on the button and then trigger the click to check the checkbox based on the data-id
document.querySelectorAll('.addon-add').forEach(button => {
    button.addEventListener('click', (e) => {
        e.preventDefault()
        const checkbox_id = button.getAttribute('data-id')
        const checkbox = document.querySelector(`#${checkbox_id}`)
        const removeButton = document.querySelector(`#${checkbox_id}-remove`);
        checkbox.click()

        // check if checkbox is checked
        if (checkbox.checked) {
            button.innerHTML = 'Added';
            button.classList.remove('btn__primary');
            button.classList.add('btn__dark');
            removeButton.classList.remove('!hidden');
        }
        else {
            button.innerHTML = 'Add';
            button.classList.remove('btn__dark');
            button.classList.add('btn__primary');
            removeButton.classList.add('!hidden');
        }

    });
});

document.querySelectorAll('.addon-remove').forEach(button => {
    button.addEventListener('click', (e) => {
        e.preventDefault()
        // get previous element
        const previousElement = button.previousElementSibling;
        // trigger click
        previousElement.click();
    });
});


/*
===========================================
Handle Flexible on dates Checkbox,
sync custom field with actual formie field
===========================================
*/
const flexibleOnDatesFront = document.querySelector('input#flexible-on-dates');
const flexibleOnDatesActual = document.querySelector('input[data-target="flexible-on-dates"]');
if (flexibleOnDatesFront && flexibleOnDatesActual) {
    // check actual field is checked and set front field to checked
    if (flexibleOnDatesActual.checked) {
        flexibleOnDatesFront.checked = true;
    }
    // listen for change on front field and trigger click on actual field
    flexibleOnDatesFront.addEventListener('change', () => {
        flexibleOnDatesActual.click();
    })
}

/*
===========================================
Sync Dates with Formie TravelDates Field
===========================================
*/
// grab the actual field dates and move it to be just when section.booking-summary starts
const bookingSummary = document.querySelector('section.booking-summary .input-date-to-be-moved-in'); // this is the section.booking-summary
if (!bookingSummary) {
    const travelDates = document.querySelector('[data-field-handle="travelDates"]');
    if (travelDates) {
        travelDates.classList.add('hidden')
    }
}
if (bookingSummary) {
    const minDate = new Date(bookingSummary.dataset.min); // this is the min date
    const maxDate = new Date(bookingSummary.dataset.max); // this is the max date
    const actualDates = document.querySelector('[data-field-handle="travelDates"]'); // actual formie field
    const selectDatesText = document.querySelector('.selected-dates-text'); // this is the text that shows the selected dates

    if (actualDates) {
        bookingSummary.insertBefore(actualDates, bookingSummary.firstChild);
        // actual dates, find input
        const actualDatesInput = actualDates.querySelector('input');

        const actualDatesArray = actualDatesInput.value.split(' to ');

        if (actualDatesArray.length < 2) { // if there is no default dates already selected from hidden field
            // get params date_from and date_to from url
            const urlParams = new URLSearchParams(window.location.search);
            const date_from = urlParams.get('date_from');
            const date_to = urlParams.get('date_to');
            // parse dates to Date
            if (date_from && date_to) { // if the dates where parsed by url parameters.
                actualDatesArray[0] = new Date(date_from);
                actualDatesArray[1] = new Date(date_to);
            }
        }

        if (actualDatesArray.length < 2) { // if there is no default dates already selected from hidden field
            actualDatesArray[0] = minDate;
            actualDatesArray[1] = maxDate;
        }



        // initialize flatpickr
        flatpickr(actualDatesInput, {
            mode: "range",
            dateFormat: "d/m/Y",
            defaultDate: [actualDatesArray[0], actualDatesArray[1]],
            minDate: minDate,
            maxDate: maxDate,
            onChange: function(selectedDates, dateStr, instance) {
                // I need to populate the Text in front page
                if (selectedDates.length === 2) {
                    const startDateStr = instance.formatDate(selectedDates[0], "d/m/Y");
                    const endDateStr = instance.formatDate(selectedDates[1], "d/m/Y");
                    selectDatesText.innerHTML = `${startDateStr} to ${endDateStr}`;

                    // update url with dates
                    const url = new URL(window.location.href);
                    url.searchParams.set('date_from', instance.formatDate(selectedDates[0], "Y-m-d"));
                    url.searchParams.set('date_to', instance.formatDate(selectedDates[1], "Y-m-d"));
                    window.history.pushState({}, '', url);
                    console.log("selectDatesActual Updated:", selectDatesText);
                }
            },
            onReady: function(selectedDates, dateStr, instance) {
                // I need to populate the Text in front page
                if (selectedDates.length === 2) {
                    const startDateStr = instance.formatDate(selectedDates[0], "d/m/Y");
                    const endDateStr = instance.formatDate(selectedDates[1], "d/m/Y");
                    selectDatesText.innerHTML = `${startDateStr} to ${endDateStr}`;
                    console.log("selectDatesActual Updated:", selectDatesText);
                }
            }
        });
    }
}





// grab the formie flexibleDates field group and move it to be just when section.booking-summary starts
const flexibleDates = document.querySelector('[data-field-handle="flexibleDates"]'); // actual formie field
if (flexibleDates) {
    const flexibleDatesContainer = document.querySelector('section.booking-summary .flexible-dates-to-move-in'); // this is the section.booking-summary
    flexibleDatesContainer.insertBefore(flexibleDates, flexibleDatesContainer.firstChild);
}






// collapsible terms and conditions
const collapsibleTermsField = document.querySelector('[data-field-handle="termsCollapsible"]');
// insert before, a link to expand the collapsible
if (collapsibleTermsField) {
    const collapsibleTermsFieldContainer = collapsibleTermsField.querySelector('.fui-input-container');
    collapsibleTermsFieldContainer.innerHTML = `<strong>Terms & Conditions</strong><br><br>` + collapsibleTermsFieldContainer.innerHTML;

    // add a container inside of collapsibleTermsField at the beginning
    collapsibleTermsField.innerHTML = `<div class="collapsible-terms-field-container mb-2 text-right text-accent"></div>` + collapsibleTermsField.innerHTML;
    const controls = collapsibleTermsField.querySelector('.collapsible-terms-field-container');


    // add a link to expand the "collapsibleTermsField" content
    const termsLink = document.createElement('a');
    termsLink.setAttribute('href', '#');
    termsLink.classList.add('terms-link');
    termsLink.classList.add('mr-2');
    termsLink.innerHTML = 'Expand';
    // add anchor inside collapsibleTermsField
    controls.appendChild(termsLink);

    // listen for click on termsLink
    termsLink.addEventListener('click', (e) => {
        e.preventDefault();
        collapsibleTermsField.classList.toggle('collapsed');
        if (termsLink.innerHTML === "Expand") {
            termsLink.innerHTML = "Collapse"
        } else {
            termsLink.innerHTML = "Expand";
        }
    });


    // add a link to print the "collapsibleTermsField" content
    const printLink = document.createElement('a');
    printLink.setAttribute('href', '#');
    printLink.classList.add('print-link');
    printLink.innerHTML = 'Print';
    controls.appendChild(printLink);
    // add event to print "collapsibleTermsFieldContainer" specific container
    printLink.addEventListener('click', (e) => {
        e.preventDefault();

        // Create a temporary element to copy the content
        const printElement = document.createElement('div');
        printElement.innerHTML = collapsibleTermsFieldContainer.innerHTML;

        // Create a new window for printing
        const printWindow = window.open('', '_blank');

        // Append the content to the new window
        printWindow.document.write('<html><head><title>Print</title></head><body>');
        printWindow.document.write(printElement.innerHTML);
        printWindow.document.write('</body></html>');

        // Close the document stream
        printWindow.document.close();

        // Print the content
        printWindow.print();

        // Close the new window after printing
        printWindow.close();
    });
}


/*
===========================================
Select first option in radio button fields[areYouTravellingAloneOrWithAFriend]
===========================================
*/
const areYouTravellingAloneOrWithAFriend = document.querySelector('[data-field-handle="areYouTravellingAloneOrWithAFriend"]');
if (areYouTravellingAloneOrWithAFriend) {
    const options = areYouTravellingAloneOrWithAFriend.querySelectorAll('input[type="radio"]');
    options.forEach((option, index) => {
        if (option.checked) {
            console.log(option)
            setTimeout(() => {
                option.click();
            }, 500);
        }
    });
}



/*
===========================================
Deposit Summary
Move the element two positions before
===========================================
*/
const page = document.querySelector('[data-index="4"][data-fui-page] .fui-page-container');
const summaryContainer = document.getElementById('deposit-container-handle');
// Move the element two positions before
if (summaryContainer && page) {
    // move one position before the last element
    console.log(page.childNodes[page.childNodes.length - 1])
    console.log(page.childNodes[page.childNodes.length - 4])
    page.insertBefore(page.childNodes[page.childNodes.length - 1], page.childNodes[page.childNodes.length - 3]);
}


/*
===========================================
Secure Payments FORM
===========================================
*/
const updateTotalCharges = async (amount, currency) => {
    if (currency !== 'NZD') {
        const storageKey = `${currency}_NZD`;
        const api_key = "0c9e0bb1-3843-4b83-a2f3-ba2c128e6a2f";

        // Check if conversion rate is already in localStorage
        let conversionRate = localStorage.getItem(storageKey);

        if (!conversionRate) {
            // If not in localStorage, fetch from API
            await fetch(`https://api.currconv.com/api/v7/convert?q=${currency}_NZD&compact=ultra&apiKey=${api_key}`)
                .then(response => response.json())
                .then(data => {
                    conversionRate = data[storageKey];
                    // Store the conversion rate in localStorage
                    localStorage.setItem(storageKey, conversionRate);
                });
        } else {
            // Parse the stored conversion rate
            conversionRate = parseFloat(conversionRate);
        }

        amount = amount * conversionRate;
    }
    return (amount * 1.03).toFixed(2);
}

const paymentAmount = document.querySelector('[data-field-handle="paymentAmount"] input');
const currencySelect = document.querySelector('[data-field-handle="currency"] select');
if (paymentAmount && currencySelect) {

    paymentAmount.addEventListener('input', async (e) => {
        let amount = e.target.value;
        const currency = currencySelect.options[currencySelect.selectedIndex].value;
        const totalCharges = document.querySelector('[data-field-handle="totalChargesInNzd"] input');
        amount = await updateTotalCharges(parseInt(amount), currency);
        totalCharges.value = amount;
    });

    currencySelect.addEventListener('change', async (e) => {
        const currency = e.target.value;
        let amount = paymentAmount.value;
        const totalCharges = document.querySelector('[data-field-handle="totalChargesInNzd"] input');
        amount = await updateTotalCharges(parseInt(amount), currency);
        totalCharges.value = amount;
    });

    // populate Secure Payments form with parameters from url
    const urlParams = new URLSearchParams(window.location.search);
    const reservationName = urlParams.get('reservationName');
    const email = urlParams.get('email');
    const amount = urlParams.get('paymentAmount');
    const currency = urlParams.get('currency');
    if (reservationName) {
        document.querySelector('[data-field-handle="reservationName"] input').value = reservationName;
    }
    if (email) {
        document.querySelector('[data-field-handle="email"] input').value = email;
    }
    if (amount && currency) {
        paymentAmount.value = amount;
        currencySelect.value = currency;
        paymentAmount.dispatchEvent(new Event('input'));
    }
}



/*
===========================================
Skip page button
===========================================
*/
const skipPageButtons = document.querySelectorAll('.skip-page-button');
if (skipPageButtons.length > 0) {
    skipPageButtons.forEach(skipPageButton => {
        skipPageButton.addEventListener('click', function(e) {
            const skipPageIndex = skipPageButton.getAttribute('data-page');
            const formFieldsToFill = document.querySelector('div[data-index="' + skipPageIndex + '"]');

            const submitButton = formFieldsToFill.querySelector('[data-submit-action="submit"]');
            submitButton.click();

        });
    })
}

/*
===========================================
Formie Weight and Height syncs
===========================================
 */
const syncFields = (weightContainer, heightContainer) => {
    const weightField = weightContainer.querySelector('input[type="text"]');
    const heightField = heightContainer.querySelector('input[type="text"]');
    // get custom weight and height fields
    const weightCustomInput = weightContainer.querySelector('input.custom-weight-input');
    const weightCustomSelect = weightContainer.querySelector('select.custom-weight-select');

    const heightCustomInput = heightContainer.querySelector('input.custom-height-input');
    const heightCustomSelect = heightContainer.querySelector('select.custom-height-select');
// on change of weight field, update custom weight fields
    if (weightField && weightCustomInput && weightCustomSelect) {
        weightField.classList.add('!hidden')
        weightCustomInput.addEventListener('input', function(e) {
            const selectedWeightType = weightCustomSelect.value
            weightField.value = e.target.value + ' ' + selectedWeightType;
        });
        weightCustomSelect.addEventListener('change', function(e) {
            if (weightCustomInput.value !== '') {
                const weight = weightCustomInput.value;
                weightField.value = weight + ' ' + e.target.value;
            }
        });
    }
    if (heightField && heightCustomInput && heightCustomSelect) {
        heightField.classList.add('!hidden')
        heightCustomInput.addEventListener('input', function(e) {
            const selectedHeightType = heightCustomSelect.value
            heightField.value = e.target.value + ' ' + selectedHeightType;
        });
        heightCustomSelect.addEventListener('change', function(e) {
            if (heightCustomInput.value !== '') {
                const height = heightCustomInput.value;
                heightField.value = height + ' ' + e.target.value;
            }
        });
    }
}


const weightContainer1 = document.querySelector('[data-field-handle="weight1"]');
const heightContainer1 = document.querySelector('[data-field-handle="height1"]');
if (weightContainer1 && heightContainer1) {
    syncFields(weightContainer1, heightContainer1);
}
const weightContainer2 = document.querySelector('[data-field-handle="weight2"]');
const heightContainer2 = document.querySelector('[data-field-handle="height2"]');
if (weightContainer2 && heightContainer2) {
    syncFields(weightContainer2, heightContainer2);
}




// This script initializes Turnstile after 2 seconds of clicking the [data-src="#subscriptionForm"] button
document.addEventListener('DOMContentLoaded', function () {
    // Initialize Turnstile after clicking the [data-src="#subscriptionForm"] button
    const subscriptionFormButton = document.querySelector('[data-src="#subscriptionForm"]');
    if (subscriptionFormButton) {
        subscriptionFormButton.addEventListener('click', function(e) {
            console.log("subscriptionFormButton clicked");
            initializeTurnstile();
        });
    }

    function initializeTurnstile() {
        // Select all Turnstile placeholders
        var placeholders = document.querySelectorAll('.formie-turnstile-placeholder');
        placeholders.forEach(function (placeholder, index) {
            // Remove whatever is inside the placeholder
            placeholder.innerHTML = '';

            // Create a new div for the widget
            var widgetDiv = document.createElement('div');
            widgetDiv.classList.add('cf-turnstile');
            placeholder.appendChild(widgetDiv);

            // Render Turnstile in the new div
            turnstile.render(widgetDiv, {
                sitekey: '0x4AAAAAAAWxD4XXJ6V0o84X',
                callback: function(token) {
                    // Update the hidden input with the response token
                    var hiddenInput = placeholder.querySelector('input[name="cf-turnstile-response"]');
                    hiddenInput.value = token;
                }
            });
        });
    }

    // Listen to the 'formie:afterSubmit' event to reinitialize Turnstile after form submission
    document.addEventListener('formie:afterSubmit', function (event) {
        console.log("formie:afterSubmit event triggered");
        initializeTurnstile();
    });
});

