import { forEach } from "lodash";
/*
===========================================
Filter options of second select based on first select
===========================================
*/
const colourSelects = document.querySelectorAll('.js-variantColour');
if (colourSelects.length > 0) {
    // FOR EACH
    colourSelects.forEach((colourSelect) => {

        colourSelect.addEventListener('change', function() {
            const colourSelect = this;
            const productId = colourSelect.dataset.productid;
            // get selected option data attributes
            const dataAttributes = colourSelect.options[this.selectedIndex].dataset;
            //console.log(dataAttributes);
            if (dataAttributes.variants)  {
                const availableVariants = JSON.parse(dataAttributes.variants);
                // get the second select
                const sizeSelect = document.querySelector('.variantSize[data-productid="' + productId + '"]')

                const hiddenVariantSize = document.querySelector('#hiddenVariantSize-' + productId);

                sizeSelect.innerHTML = '';

                forEach(availableVariants, (variant) => {
                    if(availableVariants.includes(variant)) {
                        sizeSelect.appendChild(hiddenVariantSize.querySelector('option[value="' + variant + '"]').cloneNode(true));
                    }
                });

                // // trigger the change event to update the price
                sizeSelect.dispatchEvent(new Event('change'));
            }
        });
    });

    // FOR EACH trigger the change event to filter the second select on page load
    colourSelects.forEach((colourSelect) => {
        colourSelect.dispatchEvent(new Event('change'));
    });
}


const sizeSelects = document.querySelectorAll('.variantSize');
if (sizeSelects.length > 0) {
    // FOR EACH
    sizeSelects.forEach((sizeSelect) => {

        sizeSelect.addEventListener('change', function() {
            const sizeSelect = this;
            const productId = sizeSelect.dataset.productid;
            // get the price from the selected option
            const price = sizeSelect.options[sizeSelect.selectedIndex].dataset.price;

            // update the price
            const priceElement = document.querySelector('.price-' + productId);
            priceElement.innerHTML = price;

            // Update the add to cart button if it is out of stock
            const addToCartButton = document.querySelector('.add-to-cart-' + productId);
            if (parseInt(sizeSelect.options[sizeSelect.selectedIndex].dataset.stock) === 0) {
                addToCartButton.disabled = true;
                addToCartButton.innerHTML = 'Out of stock';
                addToCartButton.classList.add('bg-accent', 'hover:bg-accent-400');
                addToCartButton.classList.remove('bg-primary', 'hover:bg-primary-600');
            } else {
                addToCartButton.disabled = false;
                addToCartButton.innerHTML = 'Add to cart';
                addToCartButton.classList.remove('bg-accent', 'hover:bg-accent-400');
                addToCartButton.classList.add('bg-primary', 'hover:bg-primary-600');
            }
        });
    });
}

