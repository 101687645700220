import gsap from 'gsap';
const readMore = document.querySelectorAll('.read-more-active');
readMore.forEach((el) => {


});

const readMoreLink = document.querySelectorAll('.read-more-expand');
if(readMoreLink) {
  readMoreLink.forEach((item) => {
    item.addEventListener('click', function (e) {
      const getHeight = item.dataset.height;
      const current = e.target;
      console.log(this);
      if(this.classList.contains('active')) {
        this.classList.remove('active');
        this.innerHTML = 'Read More';
        gsap.to(current.previousElementSibling, {height: getHeight, duration: 0.3});
      } else {
        this.classList.add('active');
        this.innerHTML = 'Read Less';
        gsap.to(current.previousElementSibling, {height: 'auto', duration: 0.3});
      }
    });
    // item.addEventListener('click', (e) => {
    //   console.log(this);
    //   const getHeight = item.dataset.height;
    //   const current = e.target;
    //
    //   if(item.classList.contains('active')) {
    //     item.classList.remove('active');
    //     item.innerHTML = 'Read More';
    //     gsap.to(current.previousElementSibling, {height: getHeight, duration: 0.3});
    //   } else {
    //     item.classList.add('active');
    //     item.innerHTML = 'Read Less';
    //     gsap.to(current.previousElementSibling, {height: 'auto', duration: 0.3});
    //   }
    // });
  });
}