const iframe = document.querySelector('.map-block .map-iframe-wrapper iframe');
const buttonsWrapper = document.querySelector('.map-block .buttons');
const buttons = document.querySelectorAll('.map-block .buttons .btn');
const viewButtonMap = document.querySelector('.map-block .view-map-btn');
if(buttonsWrapper) {

  viewButtonMap.addEventListener('click', function() {
    iframe.setAttribute('src', this.getAttribute('data-src'));
    if(buttonsWrapper) {
      buttonsWrapper.style.display = 'flex';
    }
    this.parentNode.style.display = 'none';
  });


  buttons.forEach(button => {
    button.addEventListener('click', function() {
      const getIframeSrc = this.getAttribute('data-src');
      iframe.setAttribute('src', getIframeSrc);
      buttons.forEach(function(btn2) {
        btn2.classList.remove('btn__primary');
        btn2.classList.add('btn__dark');
      });
      this.classList.add('btn__primary');
      this.classList.remove('btn__dark');
    });
  });
}