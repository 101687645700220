import Tagify from "@yaireo/tagify";

const bookingEnquireForm = document.querySelector(".booking-enquire-form-container");
if(bookingEnquireForm) {

  window.addEventListener('beforeunload', function (event) {
    event.stopImmediatePropagation();
  });

  const productList = Array.from(document.querySelectorAll(".select-tripProductList select option")).slice(1).map((item) => item.innerText);
  const productTag = bookingEnquireForm.querySelector("input[name='productTags']");
  const productInputHidden = bookingEnquireForm.querySelector("input#productHidden");
  const productTagify = new Tagify(productTag, {
    whitelist: productList,
  });

  productTagify.on('change', (e) => {
    let tags = productTagify.value.map(function(tagData) {
      return tagData.value;
    });
    productInputHidden.value = tags.join(', ');
  });

  window.addEventListener("load", () => {
    const trip = getCookie("trip");
    if(trip !== '') {
      const tripObj = JSON.parse(trip);
      if(trip) {
        tripObj.map((item) => {
          productTagify.addTags(item.value);
        });
      }
    }
  });

  document.querySelectorAll("button[name='product-enquire-btn']").forEach((item) => {
    item.addEventListener("click", () => {
      const product = item.getAttribute("data-product");
      const variantName = item.getAttribute("data-variant");
      if(!productTagify.isTagDuplicate(variantName)) {
        console.log('test');
        productTagify.addTags([variantName]);
      }
      const locStorageTrip = localStorage.getItem("trip");
      if(locStorageTrip) {
          const locStorageTripObj = JSON.parse(locStorageTrip);
          if(locStorageTripObj) {
              locStorageTripObj.map((item) => {
              if(!productTagify.isTagDuplicate(item.value)) {
                  productTagify.addTags(item.value);
              }
              });
          }
      }

      openEnquireForm();
    });
  });

  document.querySelector(".close-enquire-form").addEventListener("click", () => {
    closeEnquireForm();
  });

  document.querySelector('.tagify.fui-input').insertAdjacentHTML('afterbegin', '<label class="product-tagify-label">Add and enquire about more adventures</label>');

  const saveTripBtn = '<button class="btn btn__primary" data-action="save-trip">Save and Close</button>';
  document.querySelector('.fui-btn-wrapper').insertAdjacentHTML('afterbegin', saveTripBtn);
  // make a new parser
  document.querySelector("button[data-action='save-trip']").addEventListener("click", (e ) => {
    e.preventDefault();
    setTripCookie("trip", productTag.value, 5);
    closeEnquireForm();
  });

  function setTripCookie(name, tripName, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = name + "=" + tripName + ";" + expires + ";path=/";
  }
  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function closeEnquireForm() {
    bookingEnquireForm.classList.remove('active');
  }
  function openEnquireForm() {
    bookingEnquireForm.classList.add('active');
  }

}

