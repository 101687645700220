const itineraryDtlBtn = document.querySelector(".itinerary-detail-btn");
if(itineraryDtlBtn) {
    document.querySelector('.itinerary-detail-btn__two').addEventListener('click', (e) => {
        document.querySelector('.itinerary-detail-btn__two').style.display = 'none';
        document.querySelector('.itinerary-detail-btn__one').style.display = 'block';
        document.querySelector('.itinerary-wrapper__details__one').style.display = 'none';
        document.querySelector('.itinerary-wrapper__details__two').style.display = 'block';
    });
    document.querySelector('.itinerary-detail-btn__one').addEventListener('click', (e) => {
        document.querySelector('.itinerary-detail-btn__two').style.display = 'block';
        document.querySelector('.itinerary-detail-btn__one').style.display = 'none';
        document.querySelector('.itinerary-wrapper__details__one').style.display = 'block';
        document.querySelector('.itinerary-wrapper__details__two').style.display = 'none';
    });
}