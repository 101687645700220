import gsap from "gsap";

const faqs = document.querySelectorAll('.faqs-block');
if(faqs) {
  faqs.forEach(faq => {
    faq.querySelectorAll('.faq').forEach(item => {
      item.addEventListener('click', () => {
        setActive(item);
      });
    });
    const setActive = (item) => {
      [...item.parentElement.children].forEach((sib)=> {
        gsap.to(sib.children[1], {duration: 0.3, height: 0});
        sib.classList.remove('active');
      });
      gsap.to(item.children[1], {duration: 0.3, height: 'auto'});
      item.classList.add('active');
    }
  });
}