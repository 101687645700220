import {easepick, LockPlugin, RangePlugin} from "@easepick/bundle";

const departurebookingBlock = document.querySelector('.departure-booking-block');
if(departurebookingBlock) {
  const daterange = departurebookingBlock.querySelectorAll('input[name="daterange"]');
    if(daterange) {
      daterange.forEach(function (elem, i) {
        const todayDate = new Date()
        const picker = new easepick.create({
          element: elem,
          css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
          ],
          plugins: [RangePlugin, LockPlugin],
          RangePlugin: {
            tooltip: false,
          },
          LockPlugin: {
            minDate: elem.dataset.fromdate ? new Date(elem.dataset.fromdate) : todayDate.setDate(todayDate.getDate() + 1),
            maxDate: elem.dataset.todate ? new Date(elem.dataset.todate) : '',
          },
          format: 'DD/MM/YYYY',
          setup(picker) {
            picker.on('select', (e) => {
              const { end, start } = e.detail;
              // Date format: YYYY-MM-DD (ISO 8601)
              const startDateStr = start.toISOString().split('T')[0];
              const endDateStr = end.toISOString().split('T')[0];

              // get variant id from data attribute
              const variantId = elem.dataset.variantid;
              // get button
              const button = document.querySelector(`a.book-now-${variantId}`);
              // do something
              const href = button.getAttribute('href');
              // parse url to add dates
              const url = new URL(href);
              // get dates from bookingDatesFront
              const dates = e.target.value;
              // add dates to url
              url.searchParams.set('date_from', startDateStr);
              url.searchParams.set('date_to', endDateStr);
              // update anchor href
              button.setAttribute('href', url.toString());
              console.log(url);
            });
          },
        })
      });
    }
}
