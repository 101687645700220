// on ccc loaded
window.addEventListener('ccc.loaded', function() {
    // if form.ccc-banner__form does not exist return false
    if (!document.querySelector('form.ccc-banner__form')) {
        return false;
    }
    document.querySelector('form.ccc-banner__form').addEventListener('submit', function(event) {
    event.preventDefault(); // Prevent the form from submitting normally
    // hide #ccc
    document.querySelector('#ccc').style.display = 'none';
    // get url from form action field
    let url = "/";
    let formData = new FormData(event.target); // Create a FormData object from the form

    fetch(url, {
        method: 'POST', // or 'GET'
        body: formData
    })
    .then(data => {
        console.log("Cookie preferences saved.")
    }) // Handle the response data
    .catch(error => console.error('Error:', error)); // Handle any errors
    });
});
