import Choices from "choices.js";

const getSelect = document.querySelectorAll('.js-choice');
if (getSelect) {
    getSelect.forEach(function (select) {
        const choices = new Choices(select, {
            searchEnabled: false,
            searchChoices: false,
            allowHTML: true,
            itemSelectText: '',
            shouldSort: false,
        });

        const resetFilter = document.querySelector('.reset-filter');
        if(resetFilter) {
            choices.passedElement.element.addEventListener(
                'change',
                function (event) {
                    if(event.detail.value !== '') {
                        resetFilter.classList.add('block');
                        resetFilter.classList.remove('hidden');
                    }
                }
            )
            resetFilter.addEventListener('click', function() {
                choices.setChoiceByValue("");
                resetFilter.classList.add('hidden');
            });
        }
    });
}
