import Swiper, {Autoplay, EffectFade, Navigation, Pagination} from "swiper";
import {gsap} from "gsap";
import 'swiper/swiper-bundle.min.css';

Swiper.use([EffectFade, Autoplay, Navigation, Pagination]);

const heroSlider = document.querySelectorAll('.hero-slider-container .swiper');
heroSlider.forEach(function (elem, i) {
    new Swiper(elem, {
        //loop: true,
        slidesPerView: 1.1,
        spaceBetween: 10,
        // speed: 1000,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 1.4,
                spaceBetween: 20,
            }
        }
    })
})

const imgSlider = document.querySelectorAll('section.image-slider-block .swiper');
if (imgSlider) {
    imgSlider.forEach(function (elem, i) {
        elem.parentNode.classList.add('swiper-'+i);
        const autoplay = elem.getAttribute('data-autoplay');
        new Swiper(elem, {
            loop: true,
            slidesPerView: 1,
            speed: 1000,
            navigation: {
                nextEl: '.swiper-'+i+' .swiper-button-next',
                prevEl: '.swiper-'+i+' .swiper-button-prev',
            },
            autoplay: !!parseInt(autoplay),
        })
    })
}
