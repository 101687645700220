import gsap from "gsap";
const prepareBlock = document.querySelectorAll('.prepare-block');
if(prepareBlock) {
    prepareBlock.forEach(block => {
        if(window.innerWidth < 576) {
          block.querySelectorAll('.prepare-block__list__item').forEach(item => {
            item.addEventListener('click', () => {
              setActive(item);
            });
          });
          const setActive = (item) => {
            [...item.parentElement.children].forEach((sib)=> {
              gsap.to(sib.children[1], {duration: 0.3, height: 0});
              sib.classList.remove('active');
            });
            gsap.to(item.children[1], {duration: 0.3, height: 'auto'});
            item.classList.add('active');
          }
        }
    });
}