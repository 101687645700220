
const element = document.querySelector('.js-trip-calendar');
if (element) {
    // find all months in the ul
    const months = element.querySelectorAll('.months li');
    // find all years in the ul
    const years = element.querySelectorAll('.years li');

    const urlParams = new URLSearchParams(window.location.search);

    // on click, add the active class to the clicked element, remove it from the others
    months.forEach(month => {
        month.addEventListener('click', function() {

            // we need to update the s-val:monthFilter attr on the years
            const monthValue = this.getAttribute('data-month');
            years.forEach(year => {
                // update s-val:selectedMonth attr with monthValue variable
                year.setAttribute('s-val:monthfilter', monthValue);
                let yearHXVal = JSON.parse(year.getAttribute('data-hx-vals'));
                yearHXVal.monthFilter = monthValue;
                year.setAttribute('data-hx-vals', JSON.stringify(yearHXVal));
            })

            months.forEach(month => {
                month.classList.remove('active');
            });
            this.classList.add('active');
        });
    });

    // on click, add the active class to the clicked element, remove it from the others
    years.forEach(year => {
        year.addEventListener('click', function() {

            // we need to update the s-val:yearFilter attr on the months
            const yearValue = this.getAttribute('data-year');
            months.forEach(month => {
                // update s-val:selectedMonth attr with monthValue variable
                month.setAttribute('s-val:yearfilter', yearValue);
                let monthHXVal = JSON.parse(month.getAttribute('data-hx-vals'));
                monthHXVal.yearFilter = yearValue;
                month.setAttribute('data-hx-vals', JSON.stringify(monthHXVal));
            })


            years.forEach(year => {
                year.classList.remove('active');
            });
            this.classList.add('active');
        });
    });

    // handle click on expand button months
    const handleMonths = element.querySelector('.months-container .handle');
    handleMonths.addEventListener('click', function() {
        element.querySelector('.months-container').classList.toggle('open');
        // if handleMonths has is open
        if (element.querySelector('.months-container').classList.contains('open')) {
            // add a class to the handleMonths
            handleMonths.innerHTML = "Month -";
        }
        else {
            // remove the class to the handleMonths
            handleMonths.innerHTML = "Month +";
        }
    });

    // handle click on expand button months
    const handleYears = element.querySelector('.years-container .handle');
    handleYears.addEventListener('click', function() {
        element.querySelector('.years-container').classList.toggle('open');
        // if handleMonths has is open
        if (element.querySelector('.years-container').classList.contains('open')) {
            // add a class to the handleMonths
            handleYears.innerHTML = "Year -";
        }
        else {
            // remove the class to the handleMonths
            handleYears.innerHTML = "Year +";
        }
    });
}
