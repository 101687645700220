import Tagify from "@yaireo/tagify";

const formBlock = document.querySelectorAll('section.form-block');
if(formBlock) {
  formBlock.forEach(block => {
    const productList = Array.from(block.querySelectorAll("select[name=tags-disabled-user-input] option")).slice(1).map((item) => item.innerText);
    const productTag = block.querySelector("input[name=productTags]");
    const inputHidden = block.querySelector("input#selectYourAdventureHidden");
    const productTagify = new Tagify(productTag, {
      whitelist: productList,
      userInput: false,
      dropdown: {
        maxItems: Infinity,
      },
    });
    productTagify.on('change', (e) => {
      let tags = productTagify.value.map(function(tagData) {
        return tagData.value;
      });
      inputHidden.value = tags.join(', ');
    });
  });
}