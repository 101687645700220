import {Fancybox} from "@fancyapps/ui";

Fancybox.bind('[data-fancybox="gallery"]', {
    // Options will go here
});

Fancybox.bind('[data-fancybox]', {
    // Options will go here
});

Fancybox.bind('[data-fancybox="video"]', {
    mainClass: 'fancybox-video',
});


const countries = {
    'NZD': 'New Zealand',
    'AUD': 'Australia',
    'CAD': 'Canada',
    'CNY': 'China',
    'EUR': 'Euro',
    'GBP': 'Great Britain',
    'HKD': 'Hong Kong',
    'JPY': 'Japan',
    'SGD': 'Singapore',
    'ZAR': 'South Africa',
    'KRW': 'South Korea',
    'CHF': 'Switzerland',
    'USD': 'United States',
}

const template = `
    <div class="flex justify-between conversion p-3 mb-1 items-center" style="background: #252525">
        <div>{{COUNTRY}}</div>
        <div>{{CURRENCY}} {{PRICE}}</div>
    </div>
`


Fancybox.bind('[data-fancybox-currencies]', {
    on: {
        ready: async function (fancybox) {
            const baseCurrency = fancybox.items[0].baseCurrency;
            const price = fancybox.items[0].price;
            const target = fancybox.items[0].src;

            const pairs = Object.keys(countries)
                .filter(currency => currency !== baseCurrency)
                .map(currency => {
                    return `${baseCurrency}_${currency}`;
                }).join(',');

            const currencyConversions = [];

            const api_key = "0c9e0bb1-3843-4b83-a2f3-ba2c128e6a2f"
            await fetch(`https://api.currconv.com/api/v7/convert?q=${pairs}&compact=ultra&apiKey=${api_key}`)
                .then(response => response.json())
                .then(data => {
                    Object.keys(data).forEach(currencyConversion => {
                        const currency = currencyConversion.split('_')[1];
                        const conversionRate = data[currencyConversion];
                        const country = countries[currency];
                        const convertedPrice = (price * conversionRate).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        const html = template
                            .replace('{{COUNTRY}}', country)
                            .replace('{{CURRENCY}}', currency)
                            .replace('{{PRICE}}', convertedPrice);
                        currencyConversions.push(html);
                    });
                });

            document.querySelector(target + ' .notes-content').innerHTML = currencyConversions.join('');
        },
    },
})
